.FileDragAndDrop {
    height: 225px;
    /* max-width: 900px; */
    padding: 50px;
    border: 3px #bfbfbf dashed;
    border-radius: 13px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.FileDragAndDrop div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 1.5em !important;
    font-weight: 700;
    color: #555555;
}

.FileDragAndDrop * {pointer-events: none;}
.FileDragAndDrop #FileDragAndDropInput {pointer-events: auto;}

.FileDragAndDrop.dragover {
    border: 3px #1560bd solid;
    background-color: #e7effa;
}

.FileDragAndDrop.warning {
    border: 3px #ff6700 solid;
    background-color: #fff0e7;
}

.FileDragAndDrop i {
    font-size: 2em !important; 
    color: rgba(33, 37, 41, 0.5) !important;
}

.FileDragAndDrop.dragover i {
    color: #1560bd !important;
}

.FileDragAndDrop.warning i {
    color: #ff6700 !important;
}
/* 
.FileDragAndDrop .message {
    font-size: 2em;
} */

.FileDragAndDrop .sub-message {
    font-size: 0.7em !important;
    color:rgba(33, 37, 41, 0.5);
}