.console {
    height: 225px;
    border-radius: 0.6rem;
    overflow: visible;
}

.console .scrollable {
    /* overflow: visible; */
    margin-left: -0.5rem;
    padding-left: 0.5rem;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    scrollbar-width: none; /* hide scrollbar for firefox */
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
}

.console .scrollable::-webkit-scrollbar{
    display: none; /* hide scrollbar in Chrome, Safari, Opera, and other WebKit-based browsers */
  }

.text-light {
    color: #f8f9fa !important;
    /* color: #17a2b8!important; */
}

.console .output {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    font-size: 1.1em;
    word-wrap: break-word;
}

.console .output .symbol {
    font-size: 1.1em;
    font-weight: bolder;
    margin-right: 0.2em;
    margin-left: -0.3em;
}
